<template lang="pug">
  .app-datepicker(:class="[{'active': isActive, 'disabled': disabled}]")
    .app-datepicker-wrapper(@click="showDatepicker()")
      p.datepicker-title.unselectable {{ label }}
        br(v-if="label")
        transition(name="fade")
          span(v-if="value") {{ value | formattedDate }}

      v-menu(
        v-model="menu"
        :disabled="disabled"
        :close-on-content-click="false"
        max-width="100%"
        :content-class="'datepicker-menu'"
      )
        template(v-slot:activator="{ on, formattedDate }")
          slot(
            name="activator"
            :on="on"
            :show="showDatepicker"
            :formattedDate="formattedDate"
            :showDatepicker="showDatepicker"
            :clearDatepicker="clearDatepicker"
          )
            span(v-if="calendar").calendar-icon
            v-text-field(
              v-else
              :value="timepicker ? value : formattedDate"
              clearable
              :hide-details="hideDetails"
              :label="label"
              :data-test="testName"
              v-on="on"
              readonly
              :disabled="disabled"
              @click="showDatepicker()"
              @click:append="showDatepicker()"
              @click:clear="clearDatepicker()"
              :error="error"
              :error-messages="errorMessages"
            )
              template(v-slot:append)
                v-icon(:color="formattedDate ? 'primary' : ''") {{ icon }}
        slot(:process="datepickerProcess")
          v-date-picker(
            v-if="!timepicker"
            :value="value"
            :min="min"
            :max="max"
            :no-title="noTitle"
            @input="$emit('input', $event)"
            @change="datepickerProcess"
          )
          v-time-picker(
            v-else
            format="24hr"
            :value="value"
            @input="$emit('input', $event)"
            @change="datepickerProcess"
            :hideDetails="hideDetails"
          )
    img(src="@/assets/img/ui/close.svg" @click="$emit('clear', $event)").close-icon
</template>

<script>
import {convertToDefaultDateFormat} from "@/util"

export default {
  props: {
    value: {
      required: true
    },
    disabled: Boolean,
    label: String,
    convertToDefault: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'mdi-calendar-today'
    },
    timepicker: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      default: true,
      type: Boolean
    },
    testName: String,
    error: Boolean,
    errorMessages: String,
    min: String,
    max: String,
    noTitle: Boolean,
    calendar: Boolean
  },

  data: () => ({
    menu: false
  }),

  computed: {
    formattedDate() {
      if (!this.value) return
      if (this.convertToDefault) return convertToDefaultDateFormat(this.value)
      return this.value
    },

    isActive() {
      return !!this.value
    }
  },

  methods: {
    onChange() {
      this.$emit('change')
    },

    datepickerProcess() {
      this.menu = false
    },

    clearDatepicker() {
      this.$emit('input', '')
      this.onChange()
    },

    showDatepicker() {
      this.menu = true
    },
  }
}
</script>

<style lang="scss">
.app-datepicker {
  position: relative;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  min-width: 140px;
  max-width: 140px;
  display: flex;
  border-radius: 4px;
  background-color: #f5f7f9;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 6px;

  @include media("<=tablet") {
    min-width: 130px;
    max-width: 130px;
    margin: 0 5px 5px 0;
  }

  &-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.datepicker-menu {
  margin: 20px 0 0 -30px;
}

.close-icon {
  display: none;
  width: 26px;
  height: 26px;
  position: absolute;
  top: -10px;
  left: auto;
  right: -10px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  .active & {
    display: block;
    transition: opacity 0.3s ease-in-out;
  }
}

.datepicker-title {
  font-size: 11px;
  min-width: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $placeholder-color;
  margin-bottom: 0 !important;
  transition: color 0.3s ease-in-out;

  .active & {
    transition: color 0.3s ease-in-out;
    color: #272c32;
  }
}

.calendar-icon {
  min-width: 15px;
  min-height: 15px;
  margin-right: 5px;
  background-size: contain;
  transition: background 0.5s ease-in-out;
  background: url("../../../assets/img/ui/calendar-grey.svg") no-repeat;

  .active & {
    transition: background 0.5s ease-in-out;
    background: url("../../../assets/img/ui/calendar-black.svg") no-repeat;
  }
}
</style>
